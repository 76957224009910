import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Assembly and packaging`}</h4>
    <p>{`At the end of the fabrication process, the finished wafer contains dozens of chips in a grid pattern. During assembly and packaging, the wafer is separated into individual chips, or "dies". Each chip is mounted on a frame with wires that connect the chip to external devices, and enclosed in a protective casing. This produces the familiar look of a dark gray rectangle with metal pins at the edges.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      